<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-row no-gutters>
          <v-col cols="12">
            <span>Gerais</span>
          </v-col>
          <v-col cols="3">
            <mf-toggle v-model="flags.tribanco" class="ml-4 mr-6" :color="color" label="Tribanco" />
          </v-col>
          <v-col cols="3">
            <mf-toggle v-model="flags.ecommerce.active" class="ml-4 mr-6" :color="color" label="E-commerce" />
          </v-col>
          <v-col cols="3">
            <mf-toggle v-model="flags.cbm.active" class="ml-4 mr-6" :color="color" label="CBM" />
          </v-col>
          <v-col cols="3">
            <mf-toggle v-model="flags.app_custom_printscreen" class="ml-4 mr-6" :color="color" label="Utilizar próprias Printscreens do app" />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <span>Produtos</span>
          </v-col>
          <v-col cols="3">
            <v-tooltip top max-width="300px">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <mf-toggle v-model="flags.products_wrong_bases" class="ml-4 mr-6" :color="color" label="Bases Divergentes" />
                </div>
              </template>
              <span>
                Quando houver divergências entre as bases do E-commerce e do CBM, por exemplo, se o EAN 123 for cadastrado como uva no E-commerce e como carne
                no CBM.
              </span>
            </v-tooltip>
          </v-col>
          <v-col cols="3">
            <v-tooltip top max-width="300px">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <mf-toggle v-model="flags.use_measure_units_on_sales" class="ml-4 mr-6" :color="color" label="Mostra unidade do produto no App" />
                </div>
              </template>
              <span>As unidades de medida dos produtos importadas via integração (condicionado ao ERP) são mostradas no aplicativo.</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <span>Catálogo de produtos</span>
          </v-col>
          <v-col cols="3">
            <v-tooltip top max-width="300px">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <mf-toggle v-model="flags.use_catalog" label="Usa o catálogo" class="ml-4 mr-6" :color="color" />
                </div>
              </template>
              <span>Usa o catálogo oficial da Mercafacil durante o processamento de carga do CBM. Se falso, invalida as outras flags de catálogo.</span>
            </v-tooltip>
          </v-col>
          <v-col cols="3">
            <v-tooltip top max-width="300px">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <mf-toggle v-model="flags.use_products_images" label="Usa imagens do catálogo" class="ml-4 mr-6" :color="color" />
                </div>
              </template>
              <span>Imagens de produtos fornecidas pelo catálogo oficial da Mercafacil.</span>
            </v-tooltip>
          </v-col>
          <v-col cols="3">
            <v-tooltip top max-width="300px">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <mf-toggle v-model="flags.use_products_information" class="ml-4 mr-6" :color="color" label="Usa nomes de produtos do catálogo" />
                </div>
              </template>
              <span>Usa nomes dos produtos fornecidos pelo catálogo oficial da Mercafacil.</span>
            </v-tooltip>
          </v-col>
          <v-col cols="3">
            <v-tooltip top max-width="300px">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <mf-toggle
                    v-model="flags.use_categories"
                    class="ml-4 mr-6"
                    :color="color"
                    label="Usa categorias do catálogo (categorias para o consumidor)"
                  />
                </div>
              </template>
              <span>Informações de categorias (visíveis no app), marcas e fabricantes fornecidas pelo catálogo oficial da Mercafacil.</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <span>
              Ofertas
            </span>
          </v-col>
          <v-col cols="3">
            <v-tooltip top max-width="300px">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <mf-toggle v-model="flags.import_sales_with_specific_products" class="ml-4 mr-6" :color="color" label="Importar apenas ofertas via FTP" />
                </div>
              </template>
              <span>Apenas ofertas importadas via FTP serão tratadas na importação de ofertas.</span>
            </v-tooltip>
          </v-col>
          <v-col cols="3">
            <v-tooltip top max-width="300px">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <mf-toggle
                    v-model="flags.ignore_imported_sales_on_pos"
                    class="ml-4 mr-6"
                    :color="color"
                    label="Ignorar ofertas importadas do retaguarda no PDV"
                  />
                </div>
              </template>
              <span
                >Ofertas importadas via retaguarda não serão aplicadas no pdv. Para que esta flag tome efeito, é necessário executar a DAG realtime após
                atualizá-la.</span
              >
            </v-tooltip>
          </v-col>
          <v-col cols="3">
            <v-tooltip top max-width="300px">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <mf-toggle v-model="flags.ignore_images_on_sales" class="ml-4 mr-6" :color="color" label="Ignorar imagens nas ofertas" />
                </div>
              </template>
              <span>Todas as ofertas serão criadas sem imagens.</span>
            </v-tooltip>
          </v-col>
          <v-col cols="3">
            <v-tooltip top max-width="300px">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <mf-toggle v-model="flags.use_realtime_cashback" class="ml-4 mr-6" :color="color" label="Usar cashback realtime" />
                </div>
              </template>
              <span
                >Ofertas criadas a partir da atualização da flag serão criadas como realtime. Ofertas que já estiverem criadas previamente não são atualizadas e
                mantém o funcionamento prévio. Este comportamento é válido tanto para habilitar a flag quanto para desabilitar.</span
              >
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <span>Ofertas no app</span>
          </v-col>
          <v-col cols="3">
            <v-tooltip top max-width="300px">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <mf-toggle
                    v-model="flags.use_product_scores_sorting"
                    label="Usa score de produtos para ordenação de ofertas"
                    class="ml-4 mr-6"
                    :color="color"
                  />
                </div>
              </template>
              <span
                >Usa score geral de produtos para ordenação de ofertas. Com essa opção, produtos com mais vendas aparecem primeiro no aplicativo (obedecendo as
                ordenaçãoes de imagens e etc).</span
              >
            </v-tooltip>
          </v-col>
          <v-col cols="3">
            <v-tooltip top max-width="300px">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <mf-toggle
                    v-model="flags.use_user_product_scores_sorting"
                    label="Usa score de clientes x produtos para ordenação de ofertas"
                    class="ml-4 mr-6"
                    :color="color"
                  />
                </div>
              </template>
              <span
                >Usa um score detalhado de padrão de compra do cliente vs o produto em oferta, usando a marca e a subcategoria também para extrapolar padrão de
                consumo para produtos semelhantes. Apenas utilizado quando o usuário está logado e tem compras prévias na loja da oferta. Caso usuário não
                esteja logado, a opção de ordenação de score de produto será utilizada.</span
              >
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <span>
              Campanha
            </span>
          </v-col>
          <v-col cols="3">
            <mf-toggle v-model="flags.force_all_campaigns_update" class="ml-4 mr-6" :color="color" label="Reprocessar todas as campanhas" />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <span>
              Indústria
            </span>
          </v-col>
          <v-col cols="3">
            <v-tooltip top max-width="300px">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <mf-toggle v-model="flags.industry.has_mercapromo" class="ml-4 mr-6" :color="color" label="Mercapromo" />
                </div>
              </template>
              <span>Habilita as configurações específicas de visualização da plataforma para varejistas que participam de mercapromo.</span>
            </v-tooltip>
          </v-col>
          <v-col cols="3">
            <v-tooltip top max-width="300px">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <mf-toggle
                    v-model="mercapromoWithCbm"
                    class="ml-4 mr-6"
                    :color="color"
                    :disabled="!flags.industry.has_mercapromo"
                    :label="mercapromoWithCbm ? 'Com CBM' : 'Sem CBM'"
                  />
                </div>
              </template>
              <span>Identifica se varejista contratou a plataforma da Mercafácil.</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="auto">
        <mf-button label="Salvar" icon="send" @click="saveChanges"></mf-button>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    flags: {
      type: Object
    }
  },
  data() {
    return {
      color: '#334A58',
      mercapromoWithCbm: !this.flags.industry.only_mercapromo
    }
  },
  methods: {
    saveChanges() {
      this.$emit('click', this.mercapromoWithCbm)
    }
  }
}
</script>

<style></style>
