var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("Gerais")])]),_c('v-col',{attrs:{"cols":"3"}},[_c('mf-toggle',{staticClass:"ml-4 mr-6",attrs:{"color":_vm.color,"label":"Tribanco"},model:{value:(_vm.flags.tribanco),callback:function ($$v) {_vm.$set(_vm.flags, "tribanco", $$v)},expression:"flags.tribanco"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('mf-toggle',{staticClass:"ml-4 mr-6",attrs:{"color":_vm.color,"label":"E-commerce"},model:{value:(_vm.flags.ecommerce.active),callback:function ($$v) {_vm.$set(_vm.flags.ecommerce, "active", $$v)},expression:"flags.ecommerce.active"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('mf-toggle',{staticClass:"ml-4 mr-6",attrs:{"color":_vm.color,"label":"CBM"},model:{value:(_vm.flags.cbm.active),callback:function ($$v) {_vm.$set(_vm.flags.cbm, "active", $$v)},expression:"flags.cbm.active"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('mf-toggle',{staticClass:"ml-4 mr-6",attrs:{"color":_vm.color,"label":"Utilizar próprias Printscreens do app"},model:{value:(_vm.flags.app_custom_printscreen),callback:function ($$v) {_vm.$set(_vm.flags, "app_custom_printscreen", $$v)},expression:"flags.app_custom_printscreen"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("Produtos")])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('mf-toggle',{staticClass:"ml-4 mr-6",attrs:{"color":_vm.color,"label":"Bases Divergentes"},model:{value:(_vm.flags.products_wrong_bases),callback:function ($$v) {_vm.$set(_vm.flags, "products_wrong_bases", $$v)},expression:"flags.products_wrong_bases"}})],1)]}}])},[_c('span',[_vm._v(" Quando houver divergências entre as bases do E-commerce e do CBM, por exemplo, se o EAN 123 for cadastrado como uva no E-commerce e como carne no CBM. ")])])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('mf-toggle',{staticClass:"ml-4 mr-6",attrs:{"color":_vm.color,"label":"Mostra unidade do produto no App"},model:{value:(_vm.flags.use_measure_units_on_sales),callback:function ($$v) {_vm.$set(_vm.flags, "use_measure_units_on_sales", $$v)},expression:"flags.use_measure_units_on_sales"}})],1)]}}])},[_c('span',[_vm._v("As unidades de medida dos produtos importadas via integração (condicionado ao ERP) são mostradas no aplicativo.")])])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("Catálogo de produtos")])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('mf-toggle',{staticClass:"ml-4 mr-6",attrs:{"label":"Usa o catálogo","color":_vm.color},model:{value:(_vm.flags.use_catalog),callback:function ($$v) {_vm.$set(_vm.flags, "use_catalog", $$v)},expression:"flags.use_catalog"}})],1)]}}])},[_c('span',[_vm._v("Usa o catálogo oficial da Mercafacil durante o processamento de carga do CBM. Se falso, invalida as outras flags de catálogo.")])])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('mf-toggle',{staticClass:"ml-4 mr-6",attrs:{"label":"Usa imagens do catálogo","color":_vm.color},model:{value:(_vm.flags.use_products_images),callback:function ($$v) {_vm.$set(_vm.flags, "use_products_images", $$v)},expression:"flags.use_products_images"}})],1)]}}])},[_c('span',[_vm._v("Imagens de produtos fornecidas pelo catálogo oficial da Mercafacil.")])])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('mf-toggle',{staticClass:"ml-4 mr-6",attrs:{"color":_vm.color,"label":"Usa nomes de produtos do catálogo"},model:{value:(_vm.flags.use_products_information),callback:function ($$v) {_vm.$set(_vm.flags, "use_products_information", $$v)},expression:"flags.use_products_information"}})],1)]}}])},[_c('span',[_vm._v("Usa nomes dos produtos fornecidos pelo catálogo oficial da Mercafacil.")])])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('mf-toggle',{staticClass:"ml-4 mr-6",attrs:{"color":_vm.color,"label":"Usa categorias do catálogo (categorias para o consumidor)"},model:{value:(_vm.flags.use_categories),callback:function ($$v) {_vm.$set(_vm.flags, "use_categories", $$v)},expression:"flags.use_categories"}})],1)]}}])},[_c('span',[_vm._v("Informações de categorias (visíveis no app), marcas e fabricantes fornecidas pelo catálogo oficial da Mercafacil.")])])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v(" Ofertas ")])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('mf-toggle',{staticClass:"ml-4 mr-6",attrs:{"color":_vm.color,"label":"Importar apenas ofertas via FTP"},model:{value:(_vm.flags.import_sales_with_specific_products),callback:function ($$v) {_vm.$set(_vm.flags, "import_sales_with_specific_products", $$v)},expression:"flags.import_sales_with_specific_products"}})],1)]}}])},[_c('span',[_vm._v("Apenas ofertas importadas via FTP serão tratadas na importação de ofertas.")])])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('mf-toggle',{staticClass:"ml-4 mr-6",attrs:{"color":_vm.color,"label":"Ignorar ofertas importadas do retaguarda no PDV"},model:{value:(_vm.flags.ignore_imported_sales_on_pos),callback:function ($$v) {_vm.$set(_vm.flags, "ignore_imported_sales_on_pos", $$v)},expression:"flags.ignore_imported_sales_on_pos"}})],1)]}}])},[_c('span',[_vm._v("Ofertas importadas via retaguarda não serão aplicadas no pdv. Para que esta flag tome efeito, é necessário executar a DAG realtime após atualizá-la.")])])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('mf-toggle',{staticClass:"ml-4 mr-6",attrs:{"color":_vm.color,"label":"Ignorar imagens nas ofertas"},model:{value:(_vm.flags.ignore_images_on_sales),callback:function ($$v) {_vm.$set(_vm.flags, "ignore_images_on_sales", $$v)},expression:"flags.ignore_images_on_sales"}})],1)]}}])},[_c('span',[_vm._v("Todas as ofertas serão criadas sem imagens.")])])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('mf-toggle',{staticClass:"ml-4 mr-6",attrs:{"color":_vm.color,"label":"Usar cashback realtime"},model:{value:(_vm.flags.use_realtime_cashback),callback:function ($$v) {_vm.$set(_vm.flags, "use_realtime_cashback", $$v)},expression:"flags.use_realtime_cashback"}})],1)]}}])},[_c('span',[_vm._v("Ofertas criadas a partir da atualização da flag serão criadas como realtime. Ofertas que já estiverem criadas previamente não são atualizadas e mantém o funcionamento prévio. Este comportamento é válido tanto para habilitar a flag quanto para desabilitar.")])])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("Ofertas no app")])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('mf-toggle',{staticClass:"ml-4 mr-6",attrs:{"label":"Usa score de produtos para ordenação de ofertas","color":_vm.color},model:{value:(_vm.flags.use_product_scores_sorting),callback:function ($$v) {_vm.$set(_vm.flags, "use_product_scores_sorting", $$v)},expression:"flags.use_product_scores_sorting"}})],1)]}}])},[_c('span',[_vm._v("Usa score geral de produtos para ordenação de ofertas. Com essa opção, produtos com mais vendas aparecem primeiro no aplicativo (obedecendo as ordenaçãoes de imagens e etc).")])])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('mf-toggle',{staticClass:"ml-4 mr-6",attrs:{"label":"Usa score de clientes x produtos para ordenação de ofertas","color":_vm.color},model:{value:(_vm.flags.use_user_product_scores_sorting),callback:function ($$v) {_vm.$set(_vm.flags, "use_user_product_scores_sorting", $$v)},expression:"flags.use_user_product_scores_sorting"}})],1)]}}])},[_c('span',[_vm._v("Usa um score detalhado de padrão de compra do cliente vs o produto em oferta, usando a marca e a subcategoria também para extrapolar padrão de consumo para produtos semelhantes. Apenas utilizado quando o usuário está logado e tem compras prévias na loja da oferta. Caso usuário não esteja logado, a opção de ordenação de score de produto será utilizada.")])])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v(" Campanha ")])]),_c('v-col',{attrs:{"cols":"3"}},[_c('mf-toggle',{staticClass:"ml-4 mr-6",attrs:{"color":_vm.color,"label":"Reprocessar todas as campanhas"},model:{value:(_vm.flags.force_all_campaigns_update),callback:function ($$v) {_vm.$set(_vm.flags, "force_all_campaigns_update", $$v)},expression:"flags.force_all_campaigns_update"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v(" Indústria ")])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('mf-toggle',{staticClass:"ml-4 mr-6",attrs:{"color":_vm.color,"label":"Mercapromo"},model:{value:(_vm.flags.industry.has_mercapromo),callback:function ($$v) {_vm.$set(_vm.flags.industry, "has_mercapromo", $$v)},expression:"flags.industry.has_mercapromo"}})],1)]}}])},[_c('span',[_vm._v("Habilita as configurações específicas de visualização da plataforma para varejistas que participam de mercapromo.")])])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('mf-toggle',{staticClass:"ml-4 mr-6",attrs:{"color":_vm.color,"disabled":!_vm.flags.industry.has_mercapromo,"label":_vm.mercapromoWithCbm ? 'Com CBM' : 'Sem CBM'},model:{value:(_vm.mercapromoWithCbm),callback:function ($$v) {_vm.mercapromoWithCbm=$$v},expression:"mercapromoWithCbm"}})],1)]}}])},[_c('span',[_vm._v("Identifica se varejista contratou a plataforma da Mercafácil.")])])],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('mf-button',{attrs:{"label":"Salvar","icon":"send"},on:{"click":_vm.saveChanges}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }